<script lang="ts">
import { defineComponent, onMounted, onUnmounted, reactive, ref } from "vue"
import {
  Scene,
  WebGLRenderer,
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  GridHelper,
  AxesHelper,
  MeshBasicMaterial,
  MeshPhongMaterial,
} from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { IFCLoader } from "web-ifc-three/IFCLoader"
import { IFCWALLSTANDARDCASE, IFCWINDOW, IFCSPACE } from "web-ifc"
import * as WEBIFC from "web-ifc/web-ifc-api"

export default defineComponent({
  setup() {
    const canvas = ref<any>(null)
    let fileUploaded = false

    let scene: any, camera: any, renderer: any, controls: any
    const size = {
      width: window.innerWidth,
      height: window.innerHeight,
    }
    const rooms = reactive<any>({})

    const getSpacePoligon = (space: any) => {
      console.log(space.Representation.Representations[0])
      const poligon =
        space.Representation.Representations[1] != undefined
          ? space.Representation.Representations[1].Items[0].Elements[0].Points.map(
              (p: any) => {
                return [
                  p.Coordinates[0].value / 1000,
                  p.Coordinates[1].value / 1000,
                ]
              }
            )
          : space.Representation.Representations[0].Items[0].SweptArea.OuterCurve.Points.map(
              (p: any) => {
                return [
                  p.Coordinates[0].value / 1000,
                  p.Coordinates[1].value / 1000,
                ]
              }
            )
      return poligon
    }
    const areaCalk = (poligon: any[]) => {
      let area = 0 // Accumulates area
      let j = poligon.length - 1

      for (let i = 0; i < poligon.length; i++) {
        area +=
          (poligon[j][0] + poligon[i][0]) * (poligon[j][1] - poligon[i][1])
        j = i //j is previous vertex to i
      }
      return Math.sqrt(Math.pow(area / 2, 2))
    }

    const init = () => {
      scene = new Scene()

      const aspect = size.width / size.height
      camera = new PerspectiveCamera(75, aspect)
      camera.position.z = 15
      camera.position.y = 13
      camera.position.x = 8

      const lightColor = 0xffffff

      const ambientLight = new AmbientLight(lightColor, 0.5)
      scene.add(ambientLight)

      const directionalLight = new DirectionalLight(lightColor, 1)
      directionalLight.position.set(0, 10, 0)
      directionalLight.target.position.set(-5, 0, 0)
      scene.add(directionalLight)
      scene.add(directionalLight.target)

      renderer = new WebGLRenderer({
        alpha: true,
      })
      canvas.value.appendChild(renderer.domElement)
      renderer.setSize(size.width, size.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

      const grid = new GridHelper(50, 30)
      scene.add(grid)

      const axes = new AxesHelper()
      //   axes.material.depthTest = false
      axes.renderOrder = 1
      scene.add(axes)

      controls = new OrbitControls(camera, renderer.domElement)
      controls.enableDamping = true
      controls.target.set(-2, 0, 0)
    }

    const animate = () => {
      controls.update()
      renderer.render(scene, camera)
      requestAnimationFrame(animate)
    }

    const ifcLoader = new IFCLoader()
    const manager = ifcLoader.ifcManager

    const ifcApi = new WEBIFC.IfcAPI()
    ifcApi.SetWasmPath("../wasm/")
    ifcApi.Init()
    var myReader = new FileReader()

    manager.setWasmPath("../wasm/")
    const loadfile = async (changed: any) => {
      fileUploaded = !fileUploaded
      const file = changed.target.files[0]
      let ifcURL = URL.createObjectURL(file)

      const text = await fetch(ifcURL).then((res) => res.text())

      let modelID = ifcApi.OpenModel(text)

      console.log(
        "windows",
        ifcApi.GetLineIDsWithType(modelID, IFCWINDOW).size()
      )
      console.log(
        "walls",
        ifcApi.GetLineIDsWithType(modelID, IFCWALLSTANDARDCASE).size()
      )
      console.log("spaces", ifcApi.GetLineIDsWithType(modelID, IFCSPACE).size())

      const space = ifcApi.GetLine(
        modelID,
        ifcApi.GetLineIDsWithType(modelID, IFCSPACE).get(0),
        true
      )

      console.log(space)
      console.log(
        "test",
        areaCalk([
          [0, 0],
          [0, 1.5],
          [1, 1.5],
          [1, 0],
          [0, 0],
        ])
      )
      // console.log(getSpacePoligon(space))
      // console.log(areaCalk(getSpacePoligon(space)))

      const spaces = ifcApi.GetLineIDsWithType(modelID, IFCSPACE)
      for (let i = 0; i < spaces.size(); i++) {
        const eid = spaces.get(i)
        const object = ifcApi.GetLine(modelID, eid, true)
        if (object.LongName != null) {
          const area = areaCalk(getSpacePoligon(object))
          console.log(area)
          if (object.LongName.value in rooms) {
            rooms[object.LongName.value].area += area
          } else {
            rooms[object.LongName.value] = {}
            rooms[object.LongName.value].area = area
          }
          // names.value.add(object.LongName.value)
        }
      }
      console.log(rooms)
      // const ifcgeometry = ifcApi.GetFlatMesh(modelID, 2313)

      // console.log(ifcgeometry)
      // ifcApi.StreamAllMeshes(modelID,mesh =>{
      //     mesh.
      // })
      // const geometry = ifcApi.GetGeometry(modelID, ifcgeometry.expressID)
      //   const verts = ifcApi.GetVertexArray(
      //     geometry.GetVertexData(),
      //     geometry.GetVertexDataSize()
      //   )
      //   const indices = ifcApi.GetIndexArray(
      //     geometry.GetIndexData(),
      //     geometry.GetIndexDataSize()
      //   )
      //   console.log(verts)
      ifcLoader.load(ifcURL, (ifcModel) => {
        // console.log(ifcModel)
        // console.log(manager.getSpatialStructure(0))
        // console.log(manager.getAllItemsOfType(0, IFCWINDOW, false))
        // console.log(
        //   "walls:",
        //   manager.getAllItemsOfType(0, IFCWALLSTANDARDCASE, false).length
        // )
        // console.log(
        //   "windows:",
        //   manager.getAllItemsOfType(0, IFCWINDOW, false).length
        // )
        // console.log(
        //   "space:",
        //   manager.getAllItemsOfType(0, IFCSPACE, false).length
        // )
        // const mat = new MeshPhongMaterial({ color: "red" })
        // manager.createSubset({
        //   scene,
        //   modelID: 0,
        //   ids: manager.getAllItemsOfType(0, IFCWALLSTANDARDCASE, false),
        //   removePrevious: false,
        //   material: mat,
        // })
        // console.log(manager.getSubset(0, mat))
        // manager.getAllItemsOfType(0, IFCSPACE, false).forEach((id) => {
        //   console.log(manager.getSubset(id))
        // })
        scene.add(ifcModel)
      })
    }

    onMounted(() => {
      init()
      animate()
    })

    window.addEventListener("resize", () => {
      size.width = window.innerWidth
      size.height = window.innerHeight
      camera.aspect = size.width / size.height
      camera.updateProjectionMatrix()
      renderer.setSize(size.width, size.height)
    })

    return {
      canvas,
      loadfile,
      rooms,
    }

    // File Upload
    //
    // function ekUpload() {
    //   function Init() {
    //     console.log("Upload Initialised")

    //     var fileSelect = document.getElementById("upload-file")
    //     var fileDrag = document.getElementById("file-drag")

    //     fileSelect.addEventListener("change", fileSelectHandler, false)

    //     // Is XHR2 available?
    //     var xhr = new XMLHttpRequest()
    //     if (xhr.upload) {
    //       // File Drop
    //       fileDrag.addEventListener("dragover", fileDragHover, false)
    //       fileDrag.addEventListener("dragleave", fileDragHover, false)
    //       fileDrag.addEventListener("drop", fileSelectHandler, false)
    //     }
    //   }

    //   function fileDragHover(e) {
    //     var fileDrag = document.getElementById("file-drag")

    //     e.stopPropagation()
    //     e.preventDefault()

    //     fileDrag.className =
    //       e.type === "dragover" ? "hover" : "modal-body upload-file"
    //   }

    //   function fileSelectHandler(e) {
    //     // Fetch FileList object
    //     var files = e.target.files || e.dataTransfer.files

    //     // Cancel event and hover styling
    //     fileDragHover(e)

    //     // Process all File objects
    //     for (var i = 0, f; (f = files[i]); i++) {
    //       parseFile(f)
    //       uploadFile(f)
    //     }
    //   }

    //   // Output
    //   function output(msg) {
    //     // Response
    //     var m = document.getElementById("messages")
    //     m.innerHTML = msg
    //   }

    //   function parseFile(file) {
    //     console.log(file.name)
    //     output("<strong>" + encodeURI(file.name) + "</strong>")

    //     // var fileType = file.type;
    //     // console.log(fileType);
    //     var imageName = file.name

    //     var isGood = /\.(?=gif|jpg|png|jpeg)/gi.test(imageName)
    //     if (isGood) {
    //       document.getElementById("start").classList.add("hidden")
    //       document.getElementById("response").classList.remove("hidden")
    //       document.getElementById("notimage").classList.add("hidden")
    //       // Thumbnail Preview
    //       document.getElementById("file-image").classList.remove("hidden")
    //       document.getElementById("file-image").src = URL.createObjectURL(file)
    //     } else {
    //       document.getElementById("file-image").classList.add("hidden")
    //       document.getElementById("notimage").classList.remove("hidden")
    //       document.getElementById("start").classList.remove("hidden")
    //       document.getElementById("response").classList.add("hidden")
    //       document.getElementById("upload-file-form").reset()
    //     }
    //   }

    //   function setProgressMaxValue(e) {
    //     var pBar = document.getElementById("file-progress")

    //     if (e.lengthComputable) {
    //       pBar.max = e.total
    //     }
    //   }

    //   function updateFileProgress(e) {
    //     var pBar = document.getElementById("file-progress")

    //     if (e.lengthComputable) {
    //       pBar.value = e.loaded
    //     }
    //   }

    //   function uploadFile(file) {
    //     var xhr = new XMLHttpRequest(),
    //       fileInput = document.getElementById("class-roster-file"),
    //       pBar = document.getElementById("file-progress"),
    //       fileSizeLimit = 1024 // In MB
    //     if (xhr.upload) {
    //       // Check if file is less than x MB
    //       if (file.size <= fileSizeLimit * 1024 * 1024) {
    //         // Progress bar
    //         pBar.style.display = "inline"
    //         xhr.upload.addEventListener("loadstart", setProgressMaxValue, false)
    //         xhr.upload.addEventListener("progress", updateFileProgress, false)

    //         // File received / failed
    //         xhr.onreadystatechange = function (e) {
    //           if (xhr.readyState == 4) {
    //             // Everything is good!
    //             // progress.className = (xhr.status == 200 ? "success" : "failure");
    //             // document.location.reload(true);
    //           }
    //         }

    //         // Start upload
    //         xhr.open(
    //           "POST",
    //           document.getElementById("upload-file-form").action,
    //           true
    //         )
    //         xhr.setRequestHeader("X-File-Name", file.name)
    //         xhr.setRequestHeader("X-File-Size", file.size)
    //         xhr.setRequestHeader("Content-Type", "multipart/form-data")
    //         xhr.send(file)
    //       } else {
    //         output("Please upload a smaller file (< " + fileSizeLimit + " MB).")
    //       }
    //     }
    //   }

    //   // Check for the various File API support.
    //   if (window.File && window.FileList && window.FileReader) {
    //     Init()
    //   } else {
    //     document.getElementById("file-drag").style.display = "none"
    //   }
    // }
    // ekUpload()
  },
})
</script>


<style lang="scss" scoped>
:root {
  --primary: #5628ee;
  --success: #41d071;
  --grey-light: #99a3ba;
  --grey: #6c7486;
  --grey-dark: #3f4656;
  --light: #cdd9ed;
  --lighter: #e4ecfa;
  --shadow: rgba(18, 22, 33, 0.1);
}

.upload {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-uploaded {
    display: none;
  }

  .drop {
    --r: 0rad;
    width: 380px;
    height: 420px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 4px 24px -2px var(--shadow);
    position: relative;

    .center {
      --y: 52px;
      position: absolute;
      left: 50%;
      top: 70%;
      z-index: 6;
      width: 112px;
      height: 48px;
      transition: transform 0.3s ease;
      transform: translate(-50%, var(--y));
      label {
        color: white;
        background: var(--color-blue);
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          padding: 9px 18px;
        }

        input[type="file"] {
          display: none;
        }
      }
      // input[type="file"] {
      //   position: fixed;
      //   right: 100%;
      //   bottom: 100%;
      // }
    }
    .intro,
    .hint {
      --y: -76px;
      color: var(--grey);
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      border: 3px dotted var(--color-light-blue-grey);
      border-radius: 12px;
      padding: 50px 30px;
      width: 100%;
      max-width: 220px;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, var(--y));
      transition: opacity 0.2s ease, transform 0.4s ease;
      pointer-events: none;
      h4 {
        margin-bottom: 8px;
        color: var(--grey-dark);
        font-family: inherit;
        font-weight: 500;
        font-size: 20px;
      }
      p {
        margin: 0;
      }
    }
    .hint {
      --y: 80px;
      opacity: 0;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      &.list {
        li {
          --y: 8px;
          padding: 16px;
          display: flex;
          align-items: center;
          opacity: 0;
          transform: translateY(var(--y));
          transition: opacity 0.4s ease, transform 0.4s ease;
          &:not(:first-child) {
            border-top: 1px solid var(--lighter);
          }
          $i: 1;
          $delay: 0.16;
          @while $i <= 5 {
            $delay: $delay + 0.08;
            &:nth-child(#{$i}) {
              transition-delay: #{$delay}s;
            }
            $i: $i + 1;
          }
          img {
            border-radius: 5px;
            width: 84px;
            height: 48px;
            object-fit: cover;
            margin-right: 12px;
            box-shadow: 0 4px 12px var(--shadow);
          }
          .text {
            strong {
              display: block;
              font-size: 16px;
              font-weight: 500;
              color: var(--grey-dark);
              margin-bottom: 4px;
            }
            small {
              display: block;
              color: var(--grey-light);
              font-size: 14px;
            }
          }
          .progress {
            margin-left: auto;
            border-radius: 50%;
            background: var(--light);
            position: relative;
            &:before {
              --s: 1;
              content: "";
              width: 28px;
              height: 28px;
              left: 50%;
              top: 50%;
              z-index: 1;
              position: absolute;
              background: #fff;
              border-radius: inherit;
              transform: translate(-50%, -50%) scale(var(--s));
              transition: transform 0.32s ease;
            }
            svg {
              display: block;
              fill: none;
              &.pie {
                --s: 1;
                width: 32px;
                height: 32px;
                transform: rotate(-90deg) scale(var(--s));
                transition: transform 0.5s ease;
                circle {
                  stroke-width: 16;
                  stroke: var(--primary);
                }
              }
              &.tick {
                width: 24px;
                height: 24px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                stroke-width: 3;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke: var(--primary);
                transition: stroke 0.3s ease 0.8s;
                polyline {
                  stroke-dasharray: 18 18 18;
                  stroke-dashoffset: 18;
                  transition: stroke-dashoffset 0.4s ease 0.7s;
                }
              }
            }
            &.complete {
              background: none;
              &:before {
                --s: 0;
              }
              svg {
                &.pie {
                  --s: 0.08;
                  animation: tick 0.3s linear forwards 0.4s;
                }
                &.tick {
                  stroke: var(--success);
                  polyline {
                    stroke-dashoffset: 36;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>


<template>
  <div class="ifcviewer">
    <div class="upload" :class="{ 'is-uploaded': fileUploaded }">
      <div class="drop">
        <div class="intro">
          <h4>Drop your file here</h4>
          <p>Supports: IFC</p>
        </div>
        <div class="center">
          <label for="upload-file" id="file-drag">
            Upload
            <input type="file" id="upload-file" hidden @change="loadfile" />
          </label>
        </div>
        <div class="hint">Drop your file to upload</div>
        <ul class="list"></ul>
      </div>
    </div>
    <div class="canvas" ref="canvas"></div>
  </div>
</template>

<style scoped>
</style>